<template>
  <YsPagination ref="node" :total="total">
    <template #header>
      <el-input v-model="userName" placeholder="请输入用户姓名查找" clearable />
      <el-button type="primary" @click.prevent="query">查询</el-button>
      <el-button type="success" @click="showsetup">提现配置</el-button>
    </template>
    <el-table :data="withdrawlist" height="100%"  style="width: 100%" border>
      <el-table-column label="序号" type="index" width="80" fixed />
      <el-table-column label="用户名称" width="100" fixed>
        <template #default="scope">
          <el-tag>{{ scope.row.user.name }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="电话号码" prop="mobile" width="120">
        <template #default="scope">
          <span>{{ scope.row.user.mobile }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提现金额" prop="money" width="100" />
      <el-table-column label="手续费" prop="handleFee"  width="100"/>
      <el-table-column label="收款人" prop="bankUserName" width="100" />
      <el-table-column label="收款账户" prop="bankAccount" width="200" />
      <el-table-column label="开户银行" prop="bankName" />
      <el-table-column label="状态">
        <template #default="scope">
          <el-tag class="ml-2" type="info" v-if="scope.row.state == 1">
            待处理</el-tag>
          <el-tag class="ml-2" type="success" v-if="scope.row.state == 2">
            已支付</el-tag>
          <el-tag class="ml-2" type="danger" v-if="scope.row.state == 3">已拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="comment">
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="small" @click="addUser(scope.row)" v-if="scope.row.state == 1">
            审批</el-button>
        </template>
      </el-table-column>
    </el-table>

  </YsPagination>

  <!-- 提现审批弹窗 -->
  <el-dialog v-model="dialogFormVisible" title="提现审批" center width="540px">
    <el-form :model="addForm" :rules="rules" ref="ruleFormRef" status-icon label-width="6em">
      <el-form-item label="用户名称">
        <el-input v-model="addForm.user.name" autocomplete="off" disabled clearable />
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="addForm.user.mobile" disabled autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="提现金额">
        <el-input v-model="addForm.money" disabled autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="手续费" prop="handleFee">
        <el-input v-model="addForm.handleFee" disabled autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="处理意见" prop="state">
        <el-select v-model="addForm.state" placeholder="请选择处理意见" style="width: 100%">
          <el-option label="支付" value="2" />
          <el-option label="拒绝" value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="提现备注" prop="comment">
        <el-input v-model="addForm.comment" placeholder="请填写提现备注" autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="支付凭证" prop="paymentUrl">
        <div class="img-box" v-if="addForm.paymentUrl">
          <el-image style="width: 100px; height: 100px" :src="makeImagePath(addForm.paymentUrl)" fit="cover" />
          <div class="tips">
            <el-icon class="my-tips" @click="deletepath">
              <Delete />
            </el-icon>
          </div>
        </div>
        <div class="addimage-box" @click="addImage" v-if="!addForm.paymentUrl">
          <el-icon class="my-icon">
            <Plus />
          </el-icon>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">关闭</el-button>
        <el-button type="primary" @click="handleSubmit">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 提现配置 -->
  <el-dialog v-model="setupdialog" title="提现配置" center width="450px">
    <el-form :model="setupForm" :rules="setuprules" ref="setupFormRef" status-icon label-width="80px">
      <el-form-item label="提现费率" prop="withdrawRate">
        <el-input-number v-model="setupForm.withdrawRate" :min="1" :max="100" clearable style="width: 100%" :precision="2"/>
      </el-form-item>
      <el-form-item label="提现限额" prop="withdrawLimit">
        <el-input-number v-model="setupForm.withdrawLimit" :min="1" clearable style="width: 100%" :precision="2"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="setupdialog = false">关闭</el-button>
        <el-button type="primary" @click="handleSetup">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { withdrawListApi, updateconfigApi, getconfigApi, withdrawflowApi } from '@/api/withdraw.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { fileApi } from '@/api/good.js'

import { scaleImage, makeImagePath } from '@/utils/common.js'

export default {
  data() {
    return {
      makeImagePath,
      total: 0,
      setupForm: {
        withdrawLimit: 1,
        withdrawRate: 1
      },
      setuprules: {
        withdrawLimit: [
          { required: true, message: '请输入提现限额', trigger: 'blur' },
        ],
        withdrawRate: [
          { required: true, message: '请输入提现费率', trigger: 'blur' },
        ],

      },
      setupdialog: false,
      userName: "",
      withdrawlist: [],
      dialogFormVisible: false,
      addForm: {
      },
      formLabelWidth: '140px',
      rules: {
        state: [
          { required: true, message: '请选择提现意见', trigger: 'change' },
        ]
      }
    }
  },

  methods: {
    deletepath() {
      this.addForm.paymentUrl = ''
    },
    // 添加品牌图片
    async addImage() {
      let arr = []
      let d = await scaleImage()
            if(!d){
                return
            }
            arr[0] = d
      let res = await fileApi({ file: arr })
      if (res.meta.code === 0) {
        this.addForm.paymentUrl = res.data[0].fileId
      }
    },
    query() {
      this.$refs.node.pageNo = 1;
      this.$refs.node.queryFunc()
    },
    async showsetup() {
      let res = await getconfigApi({ field: 'withdrawRate,withdrawLimit' })
      if (res.meta.code === 0) {
        this.setupForm = res.data
        this.setupdialog = true
      }
    },
    // 提现配置
    async handleSetup() {
      let res = await updateconfigApi(this.setupForm)
      if (res.meta.code === 0) {
        this.$message.success('配置成功！')
        this.setupdialog = false
      }
    },
    // 获取提现列表数据
    async getList(obj) {
      let res = await withdrawListApi({ ...obj, userName: this.userName })
      if (res.meta.code === 0) {
        this.withdrawlist = res.data.rows
        this.total = res.data.count
      }
    },
    // 显示添加修改用户弹窗
    addUser(data) {
      this.addForm = JSON.parse(JSON.stringify(data))
      this.addForm.state = ''
      this.dialogFormVisible = true

    },
    // 保存或修改用户信息
    handleSubmit() {
      this.$refs.ruleFormRef.validate(async (valid, fields) => {
        if (valid) {
          let params = {
            "state": Number(this.addForm.state),
            "paymentUrl": this.addForm.paymentUrl,
            "comment": this.addForm.comment,
            id:this.addForm.id
          }
          let res = await withdrawflowApi(params)
          if (res.meta.code == 0) {
            this.$message.success('审批成功！')
            this.dialogFormVisible = false
            this.$refs.node.queryFunc()
          }


        } else {
          console.log('error submit!', fields)
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.content-box {
  margin: 20px;
  background-color: #ffffff;
}

.addimage-box {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #4c4d4f;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.addimage-box:hover {
  border: 1px dashed #0f54dc;
}

.img-box {
  position: relative;

  >.tips {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    position: absolute;
    font-size: 24px;
    top: 0;
    left: 0;
    cursor: pointer;
    margin-right: 10px;

    .my-tips {
      display: none;
    }
  }

  .tips:hover {
    background-color: rgba(1, 1, 1, 0.5);

    >.my-tips {
      display: block;
    }
  }
}
</style>
