/**
 * @description 封装用户相关的接口
 */

import Ajax from "@/utils/request.js";
import {WITHDRAW_URL} from "@/config/user.cfg";


/** 提现申请接口 -- start -- */
export function withdrawApplyApi(data) {
    return Ajax({
        url: WITHDRAW_URL,
        method: 'POST',
        data
    })
}
/** 提现审批接口  */
export function withdrawflowApi(data) {
    return Ajax({
        url: WITHDRAW_URL+'/'+data.id,
        method: 'PUT',
        data
    })
}
/** 提现列表接口 */
export function withdrawListApi(params) {
    return Ajax({
        url: WITHDRAW_URL,
        method: 'GET',
        params
    })
}
/** 提现信息配置 */
export function updateconfigApi(data) {
    return Ajax({
        url: '/cfg' ,
        method: 'PUT',
        data
    })
}

/** 获取提现信息配置 */
export function getconfigApi(params) {
    return Ajax({
        url: '/cfg' ,
        method: 'GET',
        params
    })
}